var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "w-100 grey lighten-4", attrs: { "fill-height": "" } },
    [
      _c(
        "main",
        { staticClass: "w-100 my-3 fill-height mx-auto" },
        [
          _c("v-container", { attrs: { fluid: "", "fill-height": "" } }, [
            _c(
              "section",
              {
                staticClass:
                  "w-100 d-flex align-items-center justify-content-center fill-height",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height",
                  },
                  [
                    _c("div", { staticClass: "mt-5" }, [
                      _c("span", { staticClass: "f24 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("Complete your profile"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "f16 grey--text text--darken-1 font-weight-normal",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "Please enter your information correctly in the field below"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        staticClass: "d-flex flex-column mt-2",
                        attrs: { "lazy-validation": "" },
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "position-relative mx-auto" },
                          [
                            _c(
                              "croppa",
                              {
                                staticClass:
                                  "text-center mt-2 pointer rounded-circle overflow-hidden",
                                attrs: {
                                  width: 150,
                                  height: 150,
                                  quality: 4,
                                  "prevent-white-space": true,
                                  placeholder: "",
                                  "canvas-color": "transparent",
                                  "show-remove-button": true,
                                  "remove-button-color": "red",
                                  "remove-button-size": 24,
                                  crossOrigin: "anonymous",
                                },
                                on: {
                                  init: _vm.onInit,
                                  "image-remove": _vm.handleNewImage,
                                  "file-choose": _vm.handleNewImage,
                                },
                                model: {
                                  value: _vm.profilePic,
                                  callback: function ($$v) {
                                    _vm.profilePic = $$v
                                  },
                                  expression: "profilePic",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    slot: "placeholder",
                                    crossorigin: "anonymous",
                                    src:
                                      _vm.doctor.pic != null
                                        ? _vm.getProfilePic
                                        : require("@/assets/images/profile-circle.png"),
                                    width: "150",
                                    height: "150",
                                  },
                                  slot: "placeholder",
                                }),
                              ]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass:
                                  "avatar-select position-absolute rounded-pill primary white--text text-center p-2",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.profilePic.chooseFile()
                                  },
                                },
                              },
                              [_vm._v(" mdi-pencil ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-between mt-auto",
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "text-center mt-4",
                              attrs: {
                                outlined: "",
                                type: "text",
                                label: _vm.$t("Enter your username"),
                                placeholder: "مثال: DrAliAhmadi",
                                rules: _vm.usernameRules,
                                required: "",
                              },
                              model: {
                                value: _vm.username,
                                callback: function ($$v) {
                                  _vm.username = $$v
                                },
                                expression: "username",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row justify-content-between mt-1",
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "text-center ms-0 me-1",
                                  attrs: {
                                    outlined: "",
                                    type: "text",
                                    label: _vm.$t("Enter your name"),
                                    rules: [(v) => !!v],
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.firstName,
                                    callback: function ($$v) {
                                      _vm.firstName = $$v
                                    },
                                    expression: "firstName",
                                  },
                                }),
                                _c("v-text-field", {
                                  staticClass: "text-center me-0 ms-1",
                                  attrs: {
                                    outlined: "",
                                    type: "text",
                                    label: _vm.$t("Enter your last name"),
                                    rules: [(v) => !!v],
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.lastName,
                                    callback: function ($$v) {
                                      _vm.lastName = $$v
                                    },
                                    expression: "lastName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("v-text-field", {
                              staticClass: "text-center number-field",
                              attrs: {
                                outlined: "",
                                type: "text",
                                label: _vm.$t("Mobile Number ( Private )"),
                                readonly: "",
                              },
                              model: {
                                value: _vm.mobileNumber,
                                callback: function ($$v) {
                                  _vm.mobileNumber = $$v
                                },
                                expression: "mobileNumber",
                              },
                            }),
                            _c("v-text-field", {
                              staticClass: "text-center number-field",
                              attrs: {
                                outlined: "",
                                type: "text",
                                label: _vm.$t("Phone Number ( for Clinic )"),
                              },
                              model: {
                                value: _vm.phoneNumber,
                                callback: function ($$v) {
                                  _vm.phoneNumber = $$v
                                },
                                expression: "phoneNumber",
                              },
                            }),
                            _c("v-textarea", {
                              attrs: {
                                outlined: "",
                                type: "text",
                                label: _vm.$t("Enter your bio"),
                                counter: "255",
                                rules: _vm.bioRules,
                                required: "",
                              },
                              model: {
                                value: _vm.bio,
                                callback: function ($$v) {
                                  _vm.bio = $$v
                                },
                                expression: "bio",
                              },
                            }),
                            _c("v-autocomplete", {
                              attrs: {
                                label: _vm.$t("Choose your gender"),
                                outlined: "",
                                required: "",
                                "small-chips": "",
                                "no-data-text": _vm.$t("No data"),
                                items: _vm.genderItems,
                                "item-text": "text",
                                "item-value": "value",
                                rules: [(v) => !!v],
                              },
                              model: {
                                value: _vm.gender,
                                callback: function ($$v) {
                                  _vm.gender = $$v
                                },
                                expression: "gender",
                              },
                            }),
                            _c("v-autocomplete", {
                              attrs: {
                                label: _vm.$t("Choose your city"),
                                items: _vm.cities,
                                "item-text": "name",
                                "item-value": "name",
                                outlined: "",
                                required: "",
                                "small-chips": "",
                                "no-data-text": _vm.$t("No data"),
                                rules: [(v) => !!v],
                              },
                              model: {
                                value: _vm.chooseCity,
                                callback: function ($$v) {
                                  _vm.chooseCity = $$v
                                },
                                expression: "chooseCity",
                              },
                            }),
                            _c("v-autocomplete", {
                              attrs: {
                                "menu-props": { bottom: true, offsetY: true },
                                items: _vm.educationItems,
                                chips: "",
                                "small-chips": "",
                                "item-text": "value",
                                "item-value": "id",
                                label: _vm.$t("Educational qualifications"),
                                loading: _vm.isLoadingEducation,
                                "return-object": "",
                                outlined: "",
                                multiple: "",
                              },
                              model: {
                                value: _vm.educations,
                                callback: function ($$v) {
                                  _vm.educations = $$v
                                },
                                expression: "educations",
                              },
                            }),
                            _c("v-autocomplete", {
                              attrs: {
                                "menu-props": { bottom: true, offsetY: true },
                                items: _vm.specialtyItems,
                                chips: "",
                                "small-chips": "",
                                "item-text": "value",
                                "item-value": "id",
                                label: _vm.$t("Work specialties"),
                                loading: _vm.isLoadingSpecialty,
                                "return-object": "",
                                outlined: "",
                                multiple: "",
                              },
                              model: {
                                value: _vm.specialties,
                                callback: function ($$v) {
                                  _vm.specialties = $$v
                                },
                                expression: "specialties",
                              },
                            }),
                            _c("v-autocomplete", {
                              attrs: {
                                "menu-props": { bottom: true, offsetY: true },
                                items: _vm.activityItems,
                                chips: "",
                                "small-chips": "",
                                "item-text": "value",
                                "item-value": "id",
                                label: _vm.$t("Field of activity"),
                                loading: _vm.isLoadingActivity,
                                "return-object": "",
                                outlined: "",
                                multiple: "",
                              },
                              model: {
                                value: _vm.activities,
                                callback: function ($$v) {
                                  _vm.activities = $$v
                                },
                                expression: "activities",
                              },
                            }),
                            _c("v-text-field", {
                              staticClass: "text-center",
                              attrs: {
                                outlined: "",
                                label: _vm.$t("Clinic name"),
                                readonly: "",
                              },
                              model: {
                                value: _vm.clinicName,
                                callback: function ($$v) {
                                  _vm.clinicName = $$v
                                },
                                expression: "clinicName",
                              },
                            }),
                            _c("div", { staticClass: "mt-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-2 d-flex flex-row align-items-stretch",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "col-8 flex-fill mx-2 13",
                                      attrs: {
                                        color: "primary",
                                        large: "",
                                        loading: _vm.submit_loading,
                                        disabled: _vm.submit_loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.validate()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Register")) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "col-4 text-white flex-fill mx-2 f13",
                                      attrs: {
                                        color: "red",
                                        large: "",
                                        loading: _vm.submit_loading,
                                        disabled: _vm.submit_loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.backToAuth()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Canceled")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }